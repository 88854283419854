import settingIcon from "../images/setting_ic.svg"
import schedulegIcon from "../images/schedule_ic.svg"
import clubIcon from "../images/club_ic.svg"
import personIcon from "../images/person_menu.svg"
import logoutIcon from "../images/logout_icon.svg"
import queIcon from "../images/que.svg"
import callIcon from "../images/call_item.svg"
import mailIcon from "../images/mail_item.svg"

export const permissions = {
  Admin: "admin",
  "View Only": "viewonly",
}

export const DATA_PERMISSIONS = [
  { label: "Admin", value: "admin" },
  { label: "View Only", value: "viewonly" },
]

export const dropdownTime = [
  { label: "00:00", value: "00:00" },
  { label: "00:30", value: "00:30" },
  { label: "1:00", value: "1:00" },
  { label: "1:30", value: "1:30" },
  { label: "2:00", value: "2:00" },
  { label: "2:30", value: "2:30" },
  { label: "3:00", value: "3:00" },
  { label: "3:30", value: "3:30" },
  { label: "4:00", value: "4:00" },
  { label: "4:30", value: "4:30" },
  { label: "5:00", value: "5:00" },
  { label: "5:30", value: "5:30" },
  { label: "6:00", value: "6:00" },
  { label: "6:30", value: "6:30" },
  { label: "7:00", value: "7:00" },
  { label: "7:30", value: "7:30" },
  { label: "8:00", value: "8:00" },
  { label: "8:30", value: "8:30" },
  { label: "9:00", value: "9:00" },
  { label: "9:30", value: "9:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "13:00", value: "13:00" },
  { label: "13:30", value: "13:30" },
  { label: "14:00", value: "14:00" },
  { label: "14:30", value: "14:30" },
  { label: "15:00", value: "15:00" },
  { label: "15:30", value: "15:30" },
  { label: "16:00", value: "16:00" },
  { label: "16:30", value: "16:30" },
  { label: "17:00", value: "17:00" },
  { label: "17:30", value: "17:30" },
  { label: "18:00", value: "18:00" },
  { label: "18:30", value: "18:30" },
  { label: "19:00", value: "19:00" },
  { label: "19:30", value: "19:30" },
  { label: "20:00", value: "20:00" },
  { label: "20:30", value: "20:30" },
  { label: "21:00", value: "21:00" },
  { label: "21:30", value: "21:30" },
  { label: "22:00", value: "22:00" },
  { label: "22:30", value: "22:30" },
  { label: "23:00", value: "23:00" },
  { label: "23:30", value: "23:30" },
]

export const numberOfPeople = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
]

export const ScheduleTime = [
  {
    time: "0",
    newTime: "0:00",
  },
  {
    time: "1",
    newTime: "1:00",
  },
  {
    time: "2",
    newTime: "2:00",
  },
  {
    time: "3",
    newTime: "3:00",
  },
  {
    time: "4",
    newTime: "4:00",
  },
  {
    time: "5",
    newTime: "5:00",
  },
  {
    time: "6",
    newTime: "6:00",
  },
  {
    time: "7",
    newTime: "7:00",
  },
  {
    time: "8",
    newTime: "8:00",
  },
  {
    time: "9",
    newTime: "9:00",
  },
  {
    time: "10",
    newTime: "10:00",
  },
  {
    time: "11",
    newTime: "11:00",
  },
  {
    time: "12",
    newTime: "12:00",
  },
  {
    time: "13",
    newTime: "13:00",
  },
  {
    time: "14",
    newTime: "14:00",
  },
  {
    time: "15",
    newTime: "15:00",
  },
  {
    time: "16",
    newTime: "16:00",
  },
  {
    time: "17",
    newTime: "17:00",
  },
  {
    time: "18",
    newTime: "18:00",
  },
  {
    time: "19",
    newTime: "19:00",
  },
  {
    time: "20",
    newTime: "20:00",
  },
  {
    time: "21",
    newTime: "21:00",
  },
  {
    time: "22",
    newTime: "22:00",
  },
  {
    time: "23",
    newTime: "23:00",
  },
  {
    time: "24",
    newTime: "24:00",
  },
]

export const DATA_SPORT = [
  { label: "Tennis", value: 1 },
  { label: "Football", value: 2 },
  { label: "Cricket", value: 3 },
]

export const DATA_OUTIN = [
  { label: "Outside", value: 1 },
  { label: "Inside", value: 2 },
]

export const DATA_STATUS = [
  { label: "Active", value: 1 },
  { label: "In Active", value: 2 },
]

export const DATA_BOOKING = [
  { label: "15 min", value: "15" },
  { label: "30 min", value: "30" },
  { label: "45 min", value: "45" },
  { label: "60 min", value: "60" },
  { label: "75 min", value: "75" },
  { label: "90 min", value: "90" },
  { label: "120 min", value: "120" },
  { label: "160 min", value: "160" },
  { label: "No time", value: "No time" },
]

export const VAT_TYPES = [
  { label: "au_abn", value: "au_abn", country: "Australia" },
  { label: "au_arn", value: "au_arn", country: "Australia" },
  { label: "eu_vat", value: "eu_vat", country: "Austria" },
  { label: "br_cnpj", value: "br_cnpj", country: "Belgium" },
]

export const toolItem = [
  {
    title: "Overview",
    icon: schedulegIcon,
    to: "/venue/schedule",
  },
  {
    title: "Profile",
    icon: clubIcon,
    to: "/venue/club-profile",
  },
  {
    title: "Settings",
    icon: settingIcon,
    to: "/venue/settings",
  },
]

export const menu_item = [
  {
    name: "User setting",
    icon: personIcon,
    to: "/venue/user-profile",
  },
  { name: "Logout", icon: logoutIcon, to: "/" },
]

export const que_item = [
  {
    name: "To help page",
    icon: queIcon,
  },
  { name: "+46725022505", icon: callIcon },
  { name: "support@racqy.com", icon: mailIcon },
]

export const DATA_TIME = [
  { label: "11:00", value: 1 },
  { label: "12:00", value: 2 },
  { label: "13:30", value: 3 },
  { label: "14:00", value: 4 },
  { label: "15:30", value: 5 },
]

export const DATA_TIME_2 = [
  { label: "11:00", value: 1 },
  { label: "12:00", value: 2 },
  { label: "13:30", value: 3 },
  { label: "14:00", value: 4 },
  { label: "15:30", value: 5 },
]

export const mobileViewMenu = [
  {
    label: "About",
    to: "https://racqy.com/about",
  },
  {
    label: "Terms & conditions",
    to: "https://racqy.com/terms-conditions",
  },
  {
    label: "Privacy policy",
    to: "https://racqy.com/privacy-policy",
  },
  {
    label: "Cookies policy",
    to: "https://racqy.com/cookies-policy",
  },
  {
    label: "Help",
    to: "https://racqy.com/contact",
  },
  {
    label: "Download App",
    to: "https://racqy.com/download",
  },
]
