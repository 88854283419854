import {
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from "@material-ui/core"
import React, { useState } from "react"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CustomToogle from "./CustomToogle"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { dropdownTime } from "../../utils/constants"
import Tooltip from "@material-ui/core/Tooltip"

const useStyle = makeStyles(theme => ({
  root: {
    width: "100%",
    margin: "10px",
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  selectRoot: {
    border: "none",
    borderRadius: "10px",
    backgroundColor: "white",
    height: "40px",
    // width: "89px",
    justifyContent: "center",
    textAlign: "center",
  },
  root2: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    columnGap: 60,
  },
  dataRoot: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    columnGap: "16px",
  },
  customToggle: {
    background: "white",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
  },
}))

const iconStyles = {
  selectIcon: {
    color: "green",
  },
}

const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ExpandMoreIcon
        {...rest}
        className={clsx(className, classes.selectIcon)}
      />
    )
  }
)

function AccordionItems({ label, isChecked, setWeekTime, removeDay }) {
  const classes = useStyle()

  const [preTime, setPreTime] = useState("8:00")
  const [postTime, setPostTime] = useState("19:00")

  const state = useSelector(state => state?.clubReducer?.clubInfo?.openingHours)

  const handleChange = event => {
    setPreTime(event.target.value)
    if (isChecked) setWeekTime(true, event.target.value)
  }

  const handleTeChange = event => {
    setPostTime(event.target.value)
    if (isChecked) setWeekTime(false, event.target.value)
  }

  let startElement = (
    <Select
      className={classes.selectRoot}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={state[label.toString().toLowerCase()].start || preTime}
      displayEmpty
      disabled={!isChecked}
      IconComponent={CustomExpandMore}
      label="Age"
      onChange={handleChange}
      disableUnderline
    >
    {dropdownTime.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
    </Select>
  )

  let endElement = (
    <Select
      className={classes.selectRoot}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={state[label.toString().toLowerCase()].end || postTime}
      displayEmpty
      disabled={!isChecked}
      IconComponent={CustomExpandMore}
      label="Age"
      onChange={handleTeChange}
      disableUnderline
    >
     {dropdownTime.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
    </Select>
  )

  console.log("label", label, "isChecked", isChecked)

  return (
    <div className={classes.root}>
      <Grid className={classes.root2}>
        <Grid item xs={6} className={classes.customToggle}>
          <CustomToogle
            label={label}
            isChecked={isChecked}
            onChange={e => {
              removeDay(label.toString().toLowerCase(), e.target.checked)
            }}
          />
        </Grid>
        <Grid item xs={6} className={classes.dataRoot}>
          <FormControl style={{ width: "100%", marginLeft: "5px" }}>
            {isChecked ? (
              startElement
            ) : (
              <Tooltip title="Enable day to set the opening hours">
                {startElement}
              </Tooltip>
            )}
          </FormControl>
          <Typography style={{ alignItems: "center", display: "flex" }}>
            -
          </Typography>
          <FormControl style={{ width: "100%" }}>
            {isChecked ? (
              endElement
            ) : (
              <Tooltip title="Enable day to set the opening hours">
                {endElement}
              </Tooltip>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}

export default AccordionItems
